import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { formatCurrency, formatShortDate } from "../../utils/CommonUtils";
import useAuthenticatedRequest from "../../utils/TokenRefreshUtils";
import useValidateShift from "../../utils/ShiftUtils";
import { useShift } from "../../context/ShiftProvider";
import Cookies from "js-cookie";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { Sidebar } from "primereact/sidebar";
import { Toast } from "primereact/toast";

import "./OpenSales.css";


const OpenSales = () => {

   const authenticatedRequest = useAuthenticatedRequest();
   const validateShift = useValidateShift();
   const toast = useRef(null);
   const navigate = useNavigate();
   const location = useLocation();
   const { isShiftOpen } = useShift();
   const currencies = ["UAH", "USD", "EUR"];
   const paymentMethods = ["Готівка", "Термінал", "IBAN"];

   const [openSales, setOpenSales] = useState([]);
   const [totalRecords, setTotalRecords] = useState(0);
   const [loading, setLoading] = useState(true);
   const [selectedSale, setSelectedSale] = useState(null);
   const [reloadData, setReloadData] = useState(false);
   const [clients, setClients] = useState(null);
   const [visible, setVisible] = useState(false);
   const [currencyRates, setCurrencyRates] = useState(null);
   const [unDividedSum, setUnDividedSum] = useState(0);
   const [totalSumUAH, setTotalSumUAH] = useState(0);
   const [lazyState, setlazyState] = useState({
      first: 0,
      rows: 10,
      page: 1
   });

   useEffect(() => {
      const fetchData = async () => {
         const url = `${process.env.REACT_APP_BACKEND_URL}cagents/get_clients/?page=1&page_size=500`;
         const options = { method: "GET", headers: { 'Content-Type': 'application/json' } };
         const response = await authenticatedRequest(url, options);
         const parsedResponse = await response.json();
         setClients(parsedResponse.results);
      };
      fetchData();
   }, [authenticatedRequest]);

   useEffect(() => {
      if (location.state !== null) {
         toast.current.show(location.state.toast);
      }

      location.state = null;
   }, [location.state, location]);

   useEffect(() => {

      const fetchData = async () => {
         const url = `${process.env.REACT_APP_BACKEND_URL}kasa_shifts/get_unfinalized_acts/?page=${lazyState.page}&page_size=${lazyState.rows}&ordering=-act_date`;
         const options = { method: "GET", headers: { 'Content-Type': 'application/json' } };
         const response = await authenticatedRequest(url, options);
         const parsedResponse = await response.json();
         console.log(parsedResponse);
         setTotalRecords(parsedResponse.count);
         setOpenSales(parsedResponse.results);
         setLoading(false);
      };

      fetchData();
   }, [authenticatedRequest, lazyState.page, lazyState.rows, reloadData]);

   useEffect(() => {
      const fetchData = async () => {
         const url = `${process.env.REACT_APP_BACKEND_URL}currency/get_currencies_list/`;
         const options = { method: "GET", headers: { 'Content-Type': 'application/json' } };
         const response = await authenticatedRequest(url, options);
         const parsedResponse = await response.json();

         const receivedRates = parsedResponse.reduce((acc, curr) => {
            acc[curr.currency_short] = curr.currency_rate;
            return acc;
         }, {});

         setCurrencyRates(receivedRates);
      };
      fetchData();

   }, [authenticatedRequest]);

   const formik = useFormik({
      initialValues: {
         products: [],
         discount: [],
         basePayments: [],
         payments: [],
         client: null
      },
      validate: values => {
         const errors = {};

         values.payments.forEach((payment, index) => {
            if (payment.paymentMethod === null || payment.paymentMethod === '') {
               if (!errors.payments) errors.payments = [];
               errors.payments[index] = { paymentMethod: 'Вкажіть спосіб оплати' };
            }
         });

         return errors
      },
      onSubmit: values => {

         const finalizeSale = async () => {

            const isShiftValid = await validateShift();

            if (!isShiftValid) {
               const toastMessage = { severity: "error", summary: "Помилка зміни", detail: "Зміна закрита", life: 3000 };
               navigate('/shift', { state: { toast: toastMessage } });
            }
            else {
               const url = `${process.env.REACT_APP_BACKEND_URL}sale_acts/make_finalized/${selectedSale.id}/`;
               const options = {
                  method: "GET",
                  headers: { 'Content-Type': 'application/json' }
               };
               const finalizeResponse = await authenticatedRequest(url, options);

               if (finalizeResponse.status === 200) {

                  let paymentRequest = [];

                  values.payments.forEach(payment => {

                     let paymentType = "CASH";
                     if (payment.paymentMethod === "Термінал") {
                        paymentType = "CARD";
                     }
                     else if (payment.paymentMethod === "IBAN") {
                        paymentType = "IBAN";
                     }

                     const singlePaymentRequest = {
                        act_id: selectedSale.id,
                        date: formatShortDate(Date.now()),
                        sum: payment.sum,
                        currency: payment.currency,
                        type: paymentType,
                        method: "INCOME",
                        cagent_id: values.client != null ? values.client.id : null,
                        shift_id: Cookies.get("ShiftId")
                     }

                     paymentRequest.push(singlePaymentRequest);
                  });

                  const createPayment = async () => {

                     const url = `${process.env.REACT_APP_BACKEND_URL}accounting_payments/create/`;
                     const options = {
                        method: "POST",
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(paymentRequest)
                     };
                     const paymentResponse = await authenticatedRequest(url, options);

                     if (paymentResponse.status === 201) {
                        const toastMessage = { severity: "success", summary: 'Оплата', detail: 'Оплата пройшла успішно', life: 2000 };
                        navigate('/shift', { state: { toast: toastMessage } });
                     }
                     else {
                        toast.current.show({ severity: "error", summary: "Помилка продажу", detail: "Помилка створення платежу у валюті, код помилки:" + finalizeResponse.status, life: 3000 });
                     }
                  }

                  createPayment();
               }
               else {
                  toast.current.show({ severity: "error", summary: "Помилка продажу", detail: "Помилка фіналізації акту, код помилки:" + finalizeResponse.status, life: 3000 });
               }
            }
         }

         finalizeSale();
      }
   })

   useEffect(() => {
      let convertedAmount = 0;

      if (formik.values.payments.length !== 0) {
         formik.values.payments.forEach(payment => {
            convertedAmount += payment.sum * currencyRates[payment.currency];
         });
      }
      
      setUnDividedSum(totalSumUAH - convertedAmount);

   }, [formik.values.payments, formik.values.discount, totalSumUAH, currencyRates]);

   const onPage = (event) => {
      event.page = event.page + 1;
      setlazyState(event);
   };

   const getClient = (id) => {
      if (id === undefined || id === null) {
         return "Невідомий клієнт";
      }

      const client = clients?.find(client => client.id === id);
      return client ? client : {};
   }

   const calculateTotals = (products) => {
      const totals = {};

      products.forEach(product => {
         const quantity = product.quantity ?? 1;
         const saleAmount = product.price * quantity;
         const currency = product.currency;

         if (!totals[currency]) {
            totals[currency] = {
               currency: currency,
               sum: 0,
               paymentMethod: null // Or any default value if needed
            };
         }

         totals[currency].sum += saleAmount;
      });

      return Object.values(totals);
   };

   const getSaleDetailsHandler = (rowData) => {

      const fetchData = async () => {
         const url = `${process.env.REACT_APP_BACKEND_URL}sale_acts/get_details/${rowData.id}/`;
         const options = { method: "GET", headers: { 'Content-Type': 'application/json' } };
         const response = await authenticatedRequest(url, options);
         const parsedResponse = await response.json();
         const modifiedSale = {};

         modifiedSale.division = parsedResponse.division;
         modifiedSale.act_date = parsedResponse.act_date;
         modifiedSale.act_number = parsedResponse.act_number;
         modifiedSale.pay_due_date = parsedResponse.pay_due_date;
         modifiedSale.id = rowData.id;
         modifiedSale.cagent = getClient(rowData.cagent);
         modifiedSale.products = [];
         modifiedSale.discount = parsedResponse.discount;

         if (parsedResponse.identified_products.length > 0) {
            modifiedSale.products = [
               ...modifiedSale.products,
               ...parsedResponse.identified_products
            ];
         }

         if (parsedResponse.unidentified_products.length > 0) {
            modifiedSale.products = [
               ...modifiedSale.products,
               ...parsedResponse.unidentified_products
            ];
         }

         if (parsedResponse.services.length > 0) {
            modifiedSale.products = [
               ...modifiedSale.products,
               ...parsedResponse.services
            ];
         }

         const totals = calculateTotals(modifiedSale.products);

         if (modifiedSale.discount !== null && modifiedSale.discount.length > 0) {
            totals.forEach(total => {
               const object = modifiedSale.discount.find(d => d.currency === total.currency);

               if(object !== undefined){
                  total.sum -= object.amount;
               }
            });
         }

         formik.setFieldValue('products', modifiedSale.products);
         formik.setFieldValue('basePayments', totals);
         formik.setFieldValue('payments', JSON.parse(JSON.stringify(totals)));
         formik.setFieldValue('discount', modifiedSale.discount);

         let amountUAH = 0;
         totals.forEach(total => {
            amountUAH += total.sum * currencyRates[total.currency];
         });

         setTotalSumUAH(amountUAH);

         setSelectedSale(modifiedSale);
      };

      fetchData();
   };

   const ChangeSaleHandler = () => {
      const route = '/newsale/';
      navigate(route, { state: { sale: selectedSale } });
   }

   const PaymentButtonHandler = () => {
      setVisible(true);
   }

   const DeleteSaleHandler = () => {

      const deleteAct = async () => {

         const url = process.env.REACT_APP_BACKEND_URL + "sale_acts/delete/" + selectedSale.id + "/";
         const options = {
            method: "DELETE",
            headers: {
               'Content-Type': 'application/json'
            }
         };

         const response = await authenticatedRequest(url, options);

         if (response.status !== 204) {
            toast.current.show({ severity: "error", summary: "Помилка видалення", detail: "Помилка сервера " + response.status, life: 3000 });
         }
         else {
            toast.current.show({ severity: "success", summary: 'Видалення', detail: 'Акт успішно видалено', life: 3000 });
         }

         setSelectedSale(null);
         formik.setFieldValue('products', []);
         setReloadData(!reloadData);
      }

      deleteAct();
   }

   const handlePaymentsAmountChange = (index, newAmount) => {
      const newPayments = [...formik.values.payments];
      newPayments[index].sum = newAmount;
      formik.setFieldValue('payments', newPayments);
   };

   const handlePaymentsCurrencyChange = (index, newCurrency) => {
      const newPayments = [...formik.values.payments];
      newPayments[index].currency = newCurrency;
      formik.setFieldValue('payments', newPayments);
   };

   const handlePaymentMethodChange = (index, value) => {
      const newPayments = [...formik.values.payments];
      newPayments[index].paymentMethod = value;
      formik.setFieldValue('payments', newPayments);
   };

   const deletePayment = (index) => {
      console.log(index);
      const newPayments = [...formik.values.payments];
      newPayments.splice(index, 1);
      formik.setFieldValue('payments', newPayments);
   };

   const addPaymentToFormik = () => {
      const newPayment = [
         ...formik.values.payments,
         { currency: "UAH", sum: 0.00, paymentMethod: null }
      ];
      formik.setFieldValue('payments', newPayment);
   };

   const itemTemplate = (item) =>
   (<div className="flex flex-wrap p-2 align-items-center gap-3">
      <div className="flex-1 flex flex-column gap-2">
         <span className="font-bold">{`${formatShortDate(item.act_date)}`}</span>
         <div className="flex align-items-center gap-2">
            <i className="pi pi-user text-sm"></i>
            <span>{clients?.find(c => c.id === item.cagent)?.name ? clients?.find(c => c.id === item.cagent)?.name : "Невідомий покупець"}</span>
         </div>
      </div>
      <span className="font-bold text-900">{formatCurrency(item.amountUAH, "UAH")}</span>
   </div>
   );

   const productNameSpan = (item) => {
      if (item.product !== undefined) {
         return item.product
      }
      else {
         return item.service
      }
   }

   const productBottomSpan = (item) => {
      if (item.identifier !== undefined) {
         return "IMEA " + item.identifier
      }
      else if (item.quantity !== undefined) {
         return "Кількість: " + item.quantity
      }
      else {
         return ""
      }
   }

   const getDiscountValue = () => {

      let message = "";

      if(formik.values.discount !== null && formik.values.discount.length > 0)
      {
         message = "Знижка: "
         formik.values.discount.forEach(discount => {
            const value = formatCurrency(discount.amount, discount.currency);
            message += (value + " ");
         });
      }
      else{
         message = "Без знижки"
      }

      return message;
   }

   const productTemplate = (item) => (<div className="flex flex-wrap p-2 align-items-center gap-3">
      <div className="flex-1 flex flex-column gap-2">
         <span className="font-bold">{productNameSpan(item)}</span>
         <div className="flex align-items-center gap-2">
            <i className="pi pi-shopping-bag text-sm"></i>
            <span>{productBottomSpan(item)}</span>
         </div>
      </div>
      <span className="font-bold text-900">{formatCurrency(item.price, item.currency)}</span>
   </div>
   );

   const tableHeader = (
      <div>{selectedSale !== null ? `Замовлення ${selectedSale?.id} (${selectedSale.cagent?.name ? selectedSale.cagent?.name : "Невідомий покупець"})` : "Детальна інфомація про замовлення"}</div>
   )

   console.log(clients);

   return (<>
      <Toast ref={toast} />
      <div className="grid card">
         <div className="col-6 flex flex-column">
            <DataTable
               value={openSales}
               selectionMode="single"
               onSelectionChange={(e) => getSaleDetailsHandler(e.value)}
               dataKey="id"
               lazy
               paginator
               rows={lazyState.rows}
               first={lazyState.first}
               totalRecords={totalRecords}
               onPage={onPage}
               emptyMessage="Незакриті замовлення відсутні"
               loading={loading}
            >
               <Column field="close_time" headerStyle={{ display: 'none' }} body={itemTemplate} />
            </DataTable>
         </div>
         <div className="col-6 card">
            <div className="flex flex-column tableContainer">
               <DataTable
                  scrollable scrollHeight="70vh"
                  header={tableHeader}
                  value={formik.values.products}
                  selectionMode="single"
                  emptyMessage="Для відображення деталей виберіть замовлення в сусідньому меню"
                  loading={loading}
               >
                  <Column field="close_time" headerStyle={{ display: 'none' }} body={productTemplate} />
               </DataTable>
            </div>
            {(selectedSale !== null && isShiftOpen) && (<div className="flex flex-column buttonsContainer">
               <Button className="mt-1 justify-content-center editButton font-bold" onClick={ChangeSaleHandler}>Змінити замовлення</Button>
               <Button className="mt-1 justify-content-center editButton font-bold" onClick={PaymentButtonHandler}>Оплатити замовлення</Button>
               <Button className="mt-1 justify-content-center editButton font-bold" onClick={DeleteSaleHandler}>Видалити замовлення</Button>
            </div>)}
         </div>
      </div>
      <Sidebar className="responsive-sidebar" header="Оплата товару" visible={visible} position="right" onHide={() => setVisible(false)}>
         <form onSubmit={formik.handleSubmit}>
            {formik.values.payments.length === 0 && (
                <div className="mb-2">
                <Message className='sideBarMessage' severity="error" text="Додайте валюту і спосіб оплати"/>
                </div>
            )}
            {formik.values.payments.map((currencyAmount, index) => (
               <div className="grid py-3" key={index}>
                  <div className="col-10 p-inputgroup py-0 flex-1">
                     <span className="currencySpan p-inputgroup-addon">
                        <Dropdown
                           className="currencyDropDown"
                           value={currencyAmount.currency}
                           onChange={e => handlePaymentsCurrencyChange(index, e.value)}
                           options={currencies.map(currency => ({ label: currency, value: currency }))}
                        />
                     </span>
                     <InputText
                        name={`currencyValuePair[${index}].amount`}
                        value={currencyAmount.sum}
                        onChange={e => handlePaymentsAmountChange(index, e.target.value)}
                     />
                  </div>
                  <div className="col-2">
                     <Button className="noStyleButton" icon="pi pi-trash" severity="danger" type='button' text onClick={() => deletePayment(index)} />
                  </div>
                  <div className="col-10 py-0">
                     <Dropdown
                        className={formik.errors.payments && formik.errors.payments[index] && formik.touched.payments && formik.touched.payments[index] ? 'paymentTypeDropDown p-invalid' : 'paymentTypeDropDown'}
                        value={currencyAmount.paymentMethod}
                        onChange={e => handlePaymentMethodChange(index, e.value)}
                        options={paymentMethods.map(method => ({ label: method, value: method }))}
                        placeholder="Спосіб оплати"
                     />
                     {formik.errors.payments && formik.errors.payments[index] && formik.touched.payments && formik.touched.payments[index] && (
                        <small className="errorSmall">{formik.errors.payments[index].paymentMethod}</small>
                     )}
                  </div>
               </div>
            ))}
            <div className='mb-2'>
               <Message className='sideBarMessage' severity={"info"} text={`${getDiscountValue()}`} />
               <Message className='sideBarMessage' severity={unDividedSum > 0 ? "warn" : "success"} text={`Нерозподілена сума: ${unDividedSum} грн.`} />
            </div>
            <div>
               <Button label="+ Додати валюту" severity="info" type="button" className="mainPageButton editButton" onClick={addPaymentToFormik} />
               {formik.values.payments.length !== 0 &&<Button label="Оплатити" severity="success" type="submit" className="mainPageButton addButton mt-1" />}
            </div>
         </form>
      </Sidebar>
   </>)
}

export default OpenSales;