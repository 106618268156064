import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import useAuthenticatedRequest from '../../utils/TokenRefreshUtils';
import useValidateShift from '../../utils/ShiftUtils';
import { formatCurrency, formatShortDate } from '../../utils/CommonUtils';
import Cookies from 'js-cookie';

import { addLocale } from 'primereact/api';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { FloatLabel } from "primereact/floatlabel";
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { RadioButton } from 'primereact/radiobutton';
import { Sidebar } from 'primereact/sidebar';
import { Toast } from 'primereact/toast';

import BarcodeScannerComponent from "react-qr-barcode-scanner";

import "./NewSale.css";
import { Card } from 'primereact/card';
import { MegaMenu } from 'primereact/megamenu';
import { InputNumber } from 'primereact/inputnumber';

const NewSale = () => {

    const toast = useRef(null);
    const location = useLocation();
    const authenticatedRequest = useAuthenticatedRequest();
    const validateShift = useValidateShift();
    const navigate = useNavigate();
    //const location = useLocation();
    const currencies = ['UAH', 'USD', 'EUR'];
    const paymentMethods = ["Готівка", "Термінал", "IBAN"];

    const [actData, setActData] = useState(
        {
            products: [],
            discount: [],
            basePayments: [],
            payments: [],
            client: null,
            isEdit: false
        }
    );
    const [paymentsPage, setPaymentsPage] = useState(false);
    const [products, setProducts] = useState([]);
    const [saleType, setSaleType] = useState(null);
    const [itemsCatalogue, setItemsCatalogue] = useState(null);
    const [itemsCataloguePage, setItemsCataloguePage] = useState(1);
    const [itemsSequence, setItemsSequence] = useState([]);
    const [filter, setFilter] = useState(null);
    const [target, setTarget] = useState([]);
    const [clientFilter, setClientFilter] = useState('');
    const [visible, setVisible] = useState(false);
    const [QRCodeScannerVisible, setQRCodeScannerVisible] = useState(false);
    const [clients, setClients] = useState([]);
    const [sideBarType, setSideBarType] = useState(null);
    const [clientSideBarVisible, setClientSideBarVisible] = useState(false);
    const [currencyRates, setCurrencyRates] = useState(null);
    const [totalSumUAH, setTotalSumUAH] = useState(0);
    const [unDividedSum, setUnDividedSum] = useState(0);
    const [unDiscountedPayments, setUndiscountedPayments] = useState([]);
    const [reloadClients, setReloadClients] = useState(true);
    const [isScanningDisabled, setIsScanningDisabled] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [cartSidebarVisible, setCartSidebarVisible] = useState(false);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "id",
        sortOrder: 1,
    });

    addLocale('ua', {
        firstDayOfWeek: 1,
        dayNames: ['неділя', 'понеділок', 'вівторок', 'середа', 'четвер', 'пятниця', 'субота'],
        dayNamesShort: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
        dayNamesMin: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
        monthNames: ['січень', 'лютий', 'березень', 'квітень', 'травень', 'червень', 'липень', 'серпень', 'вересень', 'жовтень', 'листопад', 'грудень'],
        monthNamesShort: ['січ', 'лют', 'бер', 'кві', 'тра', 'чер', 'лип', 'сер', 'вер', 'жов', 'лис', 'гру'],
        today: 'Сьогодні',
        clear: 'Очистити',
    });

    useEffect(() => {
        const fetchClients = async () => {
            const url = `${process.env.REACT_APP_BACKEND_URL}cagents/get_clients/?page=${lazyState.page}&page_size=${lazyState.rows}&ordering=${("-" + lazyState.sortField)}`;
            const options = {
                method: "GET",
                headers: { 'Content-Type': 'application/json' }
            };
            const response = await authenticatedRequest(url, options);
            if (response.status === 200) {
                const parsedResponse = await response.json();
                console.log(parsedResponse);
                setClients(parsedResponse.results);
                setTotalRecords(parsedResponse.count);
                setLoading(false);
            }
            else {
                toast.current.show({ severity: "error", summary: "Помилка завантаження", detail: "Помилка отримання списку клінєтів. Код помилки: " + response.status, life: 2000 })
                setLoading(false);
            }

        };
        fetchClients();
    }, [authenticatedRequest, reloadClients, lazyState]);

    useEffect(() => {
        const fetchCurrencies = async () => {
            const url = `${process.env.REACT_APP_BACKEND_URL}currency/get_currencies_list/`;
            const options = { method: "GET", headers: { 'Content-Type': 'application/json' } };
            const response = await authenticatedRequest(url, options);
            const parsedResponse = await response.json();

            const receivedRates = parsedResponse.reduce((acc, curr) => {
                acc[curr.currency_short] = curr.currency_rate;
                return acc;
            }, {});

            setCurrencyRates(receivedRates);
        };
        fetchCurrencies();

    }, [authenticatedRequest]);

    useEffect(() => {
        if(location.state?.sale !== undefined){
            console.log(location.state.sale);
            const actTarget = [];
            location.state.sale.products.forEach(item => {
                const newItem = {};
                const isService = item.service !== undefined;
                newItem.id = isService ? item.id : item.remaining_id;
                newItem.item_type = isService ? "SERVICE" : "PRODUCT";
                newItem.name = isService ? item.service : item.product;
                newItem.quantity = item.quantity ?? 1;
                newItem.price = item.price;
                newItem.currency = item.currency;
                newItem.product_identifier = item.identifier ?? null;
                newItem.remaining_id = item.remaining_id;

                actTarget.push(newItem);
            });
            
            setTarget(actTarget);

            const newActData = {
                products: [],
                discount: location.state.sale.discount,
                basePayments: [],
                payments: [],
                client: null,
                isEdit: true
            }

            if (location.state.sale.cagent !== null) {
                newActData.client = location.state.sale.cagent;
            }

            newActData.actDate =  location.state.sale.act_date;
            newActData.actNumber = location.state.sale.act_number;
            newActData.payDueDate = location.state.sale.pay_due_date;
            newActData.saleId = location.state.sale.id;

            setActData(newActData);
        }

    },[location.state]);

    const isFilterPresent = (filterName) => {
        console.log(filterName);
        if(filterName === undefined || filterName === null || filterName.length < 3){
            return false;
        }
        
        return true;
    }

    const GetItems = async (id, page, type, sequence, filtername) => {

        console.log(isFilterPresent(filtername));


        if (filtername === undefined || filtername === null || filtername.length >= 3) {

            setFilter(filtername ?? null);

            const url = `${process.env.REACT_APP_BACKEND_URL}kasa_shifts/get_${type === undefined ? saleType : type}_catalog/?page=${page !== undefined ? page : itemsCataloguePage}&page_size=10${id !== undefined ? "&group_id=" + id : ""}${isFilterPresent(filtername) ? "&" + type.substring(0, type.length - 1) + "_name=" + filtername : ""}`;
            const options = { method: "GET", headers: { 'Content-Type': 'application/json' } };
            const response = await authenticatedRequest(url, options);

            if (response.status === 200) {
                const parsedResponse = await response.json();
                setItemsCatalogue(parsedResponse.results);

                if (page !== undefined) {
                    setItemsCataloguePage(page);
                }

                if (type !== undefined) {
                    setSaleType(type);
                }

                if (id !== undefined) {
                    let newSequence = sequence;

                    if (sequence === undefined) {
                        newSequence = [...itemsSequence];
                        newSequence.push(id);
                    }

                    setItemsSequence(newSequence);
                }
            }
            else {
                toast.current.show({ severity: "error", summary: "Помилка отримання списку товарів", detail: `Помилка сервера: ${response.status}`, life: 2000 })
            }
        }
        else{
            setItemsCatalogue(null);
            setFilter(null);
        }
    }

    const ReturnToPreviousPage = () => {
        if(itemsSequence.length === 0){
            setItemsSequence([]);
            setItemsCatalogue(null);
        }
        else if (itemsSequence.length === 1){
            setItemsSequence([]);
            GetItems(undefined, itemsCataloguePage);
        }
        else{
            const newItemsSequence = [...itemsSequence];
            newItemsSequence.pop();
            GetItems( newItemsSequence[newItemsSequence.length - 1], 1, saleType, newItemsSequence);
        }
    }

    const onPage = (event) => {
        event.page = event.page + 1;
        setlazyState(event);
        console.log(event);
    };

    const formatDiscounts = (discounts) => {
        return discounts.filter(item => item.amount !== 0);
    }

    const formatNewSaleRequest = (values) => {

        let newSaleRequest = {};

        newSaleRequest.cagent = values.client != null ? values.client.id : null;
        newSaleRequest.division = 1;
        newSaleRequest.act_date = formatShortDate(new Date());
        newSaleRequest.pay_due_date = formatShortDate(new Date());
        newSaleRequest.act_number_autogenerate = true;
        newSaleRequest.unidentified_products = [];
        newSaleRequest.identified_products = [];
        newSaleRequest.services = [];
        newSaleRequest.shift_id = Cookies.get("ShiftId");
        newSaleRequest.discount = formatDiscounts(values.discount);

        if (formik.values.isEdit) {
            newSaleRequest.act_date = formik.values.actDate;
            newSaleRequest.act_number = formik.values.actNumber;
            newSaleRequest.pay_due_date = formik.values.payDueDate;
        }

        values.products.forEach(product => {
            if (product.product_identifier !== null) {
                let identifiedProduct = {};
                identifiedProduct.remaining_id = product.id;
                identifiedProduct.price = product.price;
                identifiedProduct.priceUAH = product.price * currencyRates[product.currency];
                identifiedProduct.currency = product.currency;

                newSaleRequest.identified_products = [
                    ...newSaleRequest.identified_products,
                    identifiedProduct
                ]
            }
            else if (product.item_type === "SERVICE") {
                let service = {};
                service.service_id = product.id;
                service.price = product.price;
                service.priceUAH = product.price * currencyRates[product.currency];
                service.currency = product.currency;

                newSaleRequest.services = [
                    ...newSaleRequest.services,
                    service
                ]
            }
            else {
                let unIdentifiedProduct = {};
                unIdentifiedProduct.remaining_id = product.id;
                unIdentifiedProduct.quantity = product.quantity;
                unIdentifiedProduct.price = product.price;
                unIdentifiedProduct.priceUAH = product.price * currencyRates[product.currency];
                unIdentifiedProduct.currency = product.currency;

                newSaleRequest.unidentified_products = [
                    ...newSaleRequest.unidentified_products,
                    unIdentifiedProduct
                ]
            }
        });

        return newSaleRequest;
    }

    const handleScannedObject = async (newScan) => {

        if(isScanningDisabled){
            return;
        }
        setIsScanningDisabled(true);

        let product = await GetProductByBarCode(newScan);
        if (product !== null) {
            moveToTargetHandler(product);
            setQRCodeScannerVisible(false);
            setIsScanningDisabled(false);
            toast.current.show({ severity: "success", summary: "Успіх додавання", detail: `Товар ${product.product_name} додано в замовлення`, life: 2000 });
        }
        else {
            toast.current.show({ severity: "error", summary: "Помилка пошуку", detail: "Товар з штрих-кодом " + newScan + " не знайдено", life: 2000 })
            setIsScanningDisabled(false);
        }
    }

    //#region Calls to backEnd
    const createSale = async (saleRequest) => {

        const url = `${process.env.REACT_APP_BACKEND_URL}sale_acts/create/`;
        const options = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(saleRequest)
        };
        const saleResponse = await authenticatedRequest(url, options);

        if (saleResponse.status === 201) {
            const parsedResponse = await saleResponse.json();
            return parsedResponse.id;
        }

        return null;
    }

    const editSale = async (saleRequest) => {

        const url = `${process.env.REACT_APP_BACKEND_URL}sale_acts/update/${formik.values.saleId}/`;
        const options = {
            method: "PUT",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(saleRequest)
        };
        const saleResponse = await authenticatedRequest(url, options);

        if (saleResponse.status === 200) {
            return true;
        }

        return null;
    }

    const finalizeSale = async (id) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}sale_acts/make_finalized/${id}/`;
        const options = {
            method: "GET",
            headers: { 'Content-Type': 'application/json' }
        };
        const finalizeResponse = await authenticatedRequest(url, options);

        return finalizeResponse;
    }

    const createPayment = async (paymentRequest) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}accounting_payments/create/`;
        const options = {
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(paymentRequest)
        };
        const paymentResponse = await authenticatedRequest(url, options);
        if (paymentResponse.status === 201) {
            return true;
        }

        return null;
    }

    const GetProductByBarCode = async (barcode) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}remainings/get_remainings/?barcode=${barcode}`;
        const options = {
            method: "GET",
            headers: { 'Content-Type': 'application/json' },
        };
        const productResponse = await authenticatedRequest(url, options);
        console.log(productResponse);
        if (productResponse.status === 200) {
            const parsedResponse = await productResponse.json();
            console.log(parsedResponse);

            if (parsedResponse.results.length > 0) {
                return parsedResponse.results[0];
            }
        }

        return null;
    }

    //#endregion

    const formik = useFormik({
        initialValues: actData,
        enableReinitialize: true,
        validate: values => {
            const errors = {};

            values.payments.forEach((payment, index) => {
                if (payment.paymentMethod === null || payment.paymentMethod === '') {
                    if (!errors.payments) errors.payments = [];
                    errors.payments[index] = { paymentMethod: 'Вкажіть спосіб оплати' };
                }
            });

            console.log(values.payments)
            console.log(errors);

            return errors;
        },
        onSubmit: (values) => {

            const performSale = async () => {

                const isShiftValid = await validateShift();

                if (!isShiftValid) {
                    const toastMessage = { severity: "error", summary: "Помилка зміни", detail: "Зміна закрита", life: 3000 };
                    navigate('/shift', { state: { toast: toastMessage } });
                }
                else {
                    const newSaleRequest = formatNewSaleRequest(values);

                    console.log(newSaleRequest);

                    const saleResponse = values.isEdit ? await editSale(newSaleRequest) : await createSale(newSaleRequest);

                    if (saleResponse !== null) {

                        const saleId = values.isEdit ? values.saleId : saleResponse;

                        const finalizeResponse = await finalizeSale(saleId);

                        if (finalizeResponse.status === 200) {

                            let paymentRequest = [];

                            values.payments.forEach(payment => {

                                let paymentType = "CASH";
                                if (payment.paymentMethod === "Термінал") {
                                    paymentType = "CARD";
                                }
                                else if (payment.paymentMethod === "IBAN") {
                                    paymentType = "IBAN";
                                }

                                const singlePaymentRequest = {
                                    act_id: saleId,
                                    date: formatShortDate(Date.now()),
                                    sum: payment.sum,
                                    currency: payment.currency,
                                    type: paymentType,
                                    method: "INCOME",
                                    cagent_id: values.client != null ? values.client.id : null,
                                    shift_id: Cookies.get("ShiftId")
                                }

                                paymentRequest.push(singlePaymentRequest);
                            });

                            const paymentResponse = createPayment(paymentRequest);

                            if (paymentResponse !== null) {
                                const toastMessage = { severity: "success", summary: 'Оплата', detail: 'Оплата пройшла успішно', life: 2000 };
                                navigate('/shift', { state: { toast: toastMessage } });
                            }
                            else {
                                toast.current.show({ severity: "error", summary: "Помилка продажу", detail: "Помилка створення платежу, код помилки:" + finalizeResponse.status, life: 2000 });
                            }
                        }
                        else {
                            toast.current.show({ severity: "error", summary: "Помилка продажу", detail: "Помилка фіналізації акту, код помилки:" + finalizeResponse.status, life: 3000 });
                        }
                    }
                    else {
                        toast.current.show({ severity: "error", summary: "Помилка продажу", detail: "Помилка створення акту", life: 3000 });
                    }
                }
            }


            performSale();
        }
    });

    const newUserFormik = useFormik({
        initialValues: {
            type: "INDIVIDUAL",
            division: 1,
            phone: "",
            email: "",
            is_partner: false,
            is_client: true,
            country: "Україна",
            region: null,
            city: "",
            street: "",
            building_number: "",
            apartment_number: "",
            notes: "",
            individual_cagent_data: {
                first_name: "",
                last_name: "",
                surname: "",
                date_of_birth: null
            },
            legal_cagent_data: {
                long_name: "",
                short_name: "",
                representative: "",
                edrpou: ""
            },
            cagent_financial_data: []
        },
        enableReinitialize: true,
        validate: values => {
            const errors = {};

            if (values.type === "INDIVIDUAL") {

                if (values.individual_cagent_data.last_name === null || values.individual_cagent_data.last_name === "") {
                    errors.indLastName = "Прізвище контрагента не повинне бути пустим";
                }

                if (values.individual_cagent_data.first_name === null || values.individual_cagent_data.first_name === "") {
                    errors.indFirstName = "Імя контрагента не повинне бути пустим";
                }

            }
            else {

                if (values.legal_cagent_data.long_name === null || values.legal_cagent_data.long_name === "") {
                    errors.legalLongName = "Повна назва організації не повинна бути пустою";
                }

                if (values.legal_cagent_data.short_name === null || values.legal_cagent_data.short_name === "") {
                    errors.legalShortName = "Коротка назва організації не повинна бути пустою";
                }
                if (values.legal_cagent_data.edrpou === null || values.legal_cagent_data.edrpou === "") {
                    errors.legalEdrpou = "ЄДРПОУ не повинен бути пустим";
                }
                else if (isNaN(Number(values.legal_cagent_data.edrpou))) {
                    errors.egalEdrpou = 'ЄДРПОУ повинен бути числом';
                }
            }

            return errors;
        },
        onSubmit: values => {
            values.country = "UKRAINE";
            values.region = "LVIV_REGION"

            if (values.individual_cagent_data?.date_of_birth) {
                values.individual_cagent_data.date_of_birth = formatShortDate(values.individual_cagent_data.date_of_birth);
            }

            const { type, individual_cagent_data, legal_cagent_data, ...rest } = values;

            values = {
                type,
                ...rest,
                ...(type === "INDIVIDUAL" ? { individual_cagent_data } : { legal_cagent_data })
            };

            console.log(values);

            const addCounterAgentHandler = async event => {

                const url = process.env.REACT_APP_BACKEND_URL + "cagents/create/";
                const method = "POST";

                const options = {
                    method: method,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(values)
                };

                const response = await authenticatedRequest(url, options);
                console.log(response);

                setReloadClients(!reloadClients);
                newUserFormik.resetForm();
                setClientSideBarVisible(false);
            }

            addCounterAgentHandler();
        }
    });

    const saveDraft = async () => {

        const isShiftValid = await validateShift();

        if (!isShiftValid) {
            const toastMessage = { severity: "error", summary: "Помилка зміни", detail: "Зміна закрита", life: 3000 };
            navigate('/shift', { state: { toast: toastMessage } });
        } else {

            const newSaleRequest = formatNewSaleRequest(formik.values);
            const isEdit = formik.values.isEdit;
            const saleResponse = isEdit ? await editSale(newSaleRequest) : await createSale(newSaleRequest);
            console.log(newSaleRequest);
            console.log(saleResponse);
            const id = isEdit ? formik.values.saleId : saleResponse;

            if (saleResponse !== null) {
                const toastMessage = isEdit ?
                    { severity: "success", summary: "Акт успішно відрудаговано", detail: "Драфт акту " + id + " успішно відредаговано", life: 2000 } :
                    { severity: "success", summary: "Акт успішно збережено", detail: "Драфт акту " + id + " успішно збережено", life: 2000 };
                navigate('/shift', { state: { toast: toastMessage } });
            }
            else {
                toast.current.show({ severity: "error", summary: "Помилка збереження акту", detail: "Помилка збереження акту", life: 2000 });
            }
        }
    }

    useEffect(() => {

        let discountAmount = 0;
        let convertedAmount = 0;

        if(currencyRates !== null){
            if (formik.values.discount?.length !== 0) {
                formik.values.discount.forEach(discount => {
                    discountAmount += discount.amount * currencyRates[discount.currency];
                });
            }
    
            if (formik.values.payments.length !== 0) {
                formik.values.payments.forEach(payment => {
                    convertedAmount += payment.sum * currencyRates[payment.currency];
                });
            }
        }
       
        setUnDividedSum(totalSumUAH - convertedAmount - discountAmount);

    }, [formik.values.payments, formik.values.discount, totalSumUAH, currencyRates]);

    const quantityFormik = useFormik({
        initialValues: {
            product: {},
            quantity: 1,
            isAdd: true
        },
        enableReinitialize: true,
        onSubmit: (values) => {

            if (values.isAdd) {

                const index = target.findIndex(item => item.id === values.product.id);

                if (index >= 0) {
                    let newTarget = target;

                    if (newTarget[index].available_quantity === values.quantity) {
                        newTarget[index].quantity += +values.quantity;
                        newTarget[index].available_quantity -= +values.quantity;
                        const newSource = products.filter(item => item.id !== newTarget[index].id);

                        setProducts(newSource);
                    }
                    else {
                        newTarget[index].quantity += +values.quantity;
                        newTarget[index].available_quantity -= +values.quantity;
                    }

                    setTarget(newTarget);
                }
                else {
                    if (values.product.available_quantity === +values.quantity) {
                        const newSource = products.filter(item => item.id !== values.product.id);

                        setProducts(newSource);
                    }
                    const newProduct = values.product;
                    newProduct.quantity = +values.quantity;
                    newProduct.available_quantity = values.product.available_quantity - +values.quantity;

                    const newTarget = [
                        ...target,
                        newProduct
                    ];

                    setTarget(newTarget);
                }
            }
            else {
                const index = products.findIndex(item => item.id === values.product.id);

                if (index >= 0) {
                    if (values.product.quantity === +values.quantity) {
                        const newTarget = target.filter(item => item.id !== values.product.id);

                        setTarget(newTarget);
                    }

                    let newSource = products;
                    newSource[index].quantity -= +values.quantity;
                    newSource[index].available_quantity += +values.quantity;

                    setProducts(newSource);
                }
                else {

                    if (values.product.quantity === +values.quantity) {
                        const newTarget = target.filter(item => item.id !== values.product.id);

                        setTarget(newTarget);
                    }

                    const newProduct = values.product;
                    newProduct.available_quantity += +values.quantity;
                    newProduct.quantity -= +values.quantity;

                    const newSource = [
                        ...products,
                        newProduct
                    ]

                    setProducts(newSource);
                }
            }

            setVisible(false);
            quantityFormik.resetForm();
        }
    })

    const onSelectionChange = (e) => {
        formik.setFieldValue("client", e.value || null);
    };

    const addNewClient = () => {
        setClientSideBarVisible(true);
    }

    const clearClient = () => {
        formik.setFieldValue("client", null);
    };

    const handleCurrencyChange = (index, value) => {
        const discount = [...formik.values.discount];
        discount[index].currency = value;
        formik.setFieldValue('discount', discount);
    };

    const handleAmountChange = (index, value) => {
        const discount = [...formik.values.discount];
        discount[index].amount = value;
        formik.setFieldValue('discount', discount);
    };

    const handlePaymentsAmountChange = (index, newAmount) => {
        const newPayments = [...formik.values.payments];
        newPayments[index].sum = newAmount;
        formik.setFieldValue('payments', newPayments);
    };

    const handlePaymentsCurrencyChange = (index, newCurrency) => {
        const newPayments = [...formik.values.payments];
        newPayments[index].currency = newCurrency;
        formik.setFieldValue('payments', newPayments);
    };

    const handlePaymentMethodChange = (index, value) => {
        const newPayments = [...formik.values.payments];
        newPayments[index].paymentMethod = value;
        formik.setFieldValue('payments', newPayments);
    };

    const deletePayment = (index) => {
        const newPayments = [...formik.values.payments];
        newPayments.splice(index, 1);
        formik.setFieldValue('payments', newPayments);
    };

    const addPaymentToFormik = () => {
        const newPayment = [
            ...formik.values.payments,
            { currency: "UAH", sum: 0.00, paymentMethod: null }
        ];
        formik.setFieldValue('payments', newPayment);
    };

    const existsDiscount = () => {
        let showDiscounts = false;

        formik.values.discount.forEach(discount => {
            if (discount.amount > 0) {
                showDiscounts = true;
            }
        });

        return showDiscounts;
    }

    const showPaymentSideBar = () => {

        setUndiscountedPayments(formik.values.payments);
        console.log(formik.values.payments);

        if (existsDiscount()) {

            const existingDiscounts = formik.values.discount;
            const existingCurrencyMap = new Map(existingDiscounts.map(discount => [discount.currency, discount.amount]));

            const paymentsWithDiscount = [];
            formik.values.payments.forEach(payment => {
                const currency = payment.currency;
                const amountFromExisting = existingCurrencyMap.get(currency) || 0;

                paymentsWithDiscount.push({
                    currency: currency,
                    sum: payment.sum - +amountFromExisting
                });
            });

            formik.setFieldValue("payments", paymentsWithDiscount);

        }

        sidebarHandler("payment");
    }

    const moveToTargetHandler = (product) => {

        const newItem = {};
        newItem.id = product.id;
        newItem.item_type = "PRODUCT";
        newItem.name = product.product_name;
        newItem.quantity = 1;
        newItem.product_identifier = product.identifier ?? null;
        newItem.price = product.sale_price;
        newItem.currency = product.sale_price_currency;

        const newTarget = [...target];
        newTarget.push(newItem);
        setTarget(newTarget);
    }

    const sidebarHandler = (name) => {
        setSideBarType(name);
        setVisible(true);
    };

    const deleteSaleHandler = () => {
        const deleteAct = async () => {

            const url = process.env.REACT_APP_BACKEND_URL + "sale_acts/delete/" + formik.values.saleId + "/";
            const options = {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);

            let message = "";
            if (response.status !== 204) {
                message = { severity: "error", summary: "Помилка видалення", detail: "Помилка сервера " + response.status, life: 1000 };
            }
            else {
                message = { severity: "success", summary: 'Видалення', detail: 'Акт успішно видалено', life: 1000 };
            }

            navigate("/opensales", { state: { toast: message } })
        }

        deleteAct();
    }

    const HideSideBar = (type) => {
        if (type === "payment") {
            formik.setFieldValue("payments", unDiscountedPayments);
        }

        setVisible(false);
    }

    //#region Sidebar bodies

    const sideBarHeader = (
        <div className="flex">
            <div className="flex-1 flex align-items-center justify-content-center font-semibold">
                {sideBarType === "client" && "Виберіть клієнта"}
                {sideBarType === "discount" && "Виберіть знижку"}
                {sideBarType === "payment" && "Оплата товару"}
                {sideBarType === "quantity" && "Виберіть кількість товару"}
                {sideBarType === "delete" && "Видалення акту"}
            </div>
        </div>
    );

    const clientSideBarHeader = (
        <div className="grid flex">
            <div className='col-10'>
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText placeholder="Пошук" value={clientFilter} onChange={(e) => setClientFilter(e.target.value)} />
                </IconField>
            </div>
            <div className='col-2 justify-content-end'>
                <Button icon="pi pi-plus" rounded text aria-label="Filter" className="editButttonNoBackground" severity="info" onClick={addNewClient} />
            </div>
        </div>
    );

    const clientSideBarBody = (
        <>
            {formik.values.client && <Button icon="pi pi-trash" severity="danger" className="pageButton deleteButton" onClick={clearClient}></Button>}
            <DataTable
                lazy
                rows={lazyState.rows}
                paginator
                paginatorTemplate="RowsPerPageDropdown PrevPageLink CurrentPageReport NextPageLink"
                currentPageReportTemplate="{first} до {last}"
                onPage={onPage}
                sortField={lazyState.sortField}
                sortOrder={lazyState.sortOrder}
                first={lazyState.first}
                loading={loading}
                totalRecords={totalRecords}
                rowsPerPageOptions={[10, 20]}
                value={clients.filter(item => item.name.toLowerCase().includes(clientFilter.toLowerCase()))}
                header={clientSideBarHeader}
                selectionMode="single"
                selection={formik.values.client}
                onSelectionChange={onSelectionChange}
                dataKey="id"
            >
                <Column field="name" headerStyle={{ display: 'none' }}></Column>
            </DataTable>
        </>
    );

    const discountSideBarBody = () => (
        <>
            {formik.values.discount.map((currencyAmount, index) => (
                <div className="grid" key={index}>
                    <div className="col-10 p-inputgroup flex-1 mt-2 mb-2">
                        <span className="currencySpan p-inputgroup-addon">
                            <Dropdown
                                className="currencyDropDown"
                                value={currencyAmount.currency}
                                onChange={e => handleCurrencyChange(index, e.value)}
                                options={currencies.map(currency => ({ label: currency, value: currency }))}
                            />
                        </span>
                        <InputText
                            name={`currencyValuePair[${index}].amount`}
                            value={currencyAmount.amount}
                            onChange={e => handleAmountChange(index, e.target.value)}
                            className={formik.errors.currencyValuePair && formik.touched.currencyValuePair ? 'p-invalid' : ''}
                        />
                    </div>
                    <div className="col-2 mt-2 mb-2">
                        <Button className="noStyleButton" icon="pi pi-trash" severity="danger" type='button' button text onClick={() => handleAmountChange(index, 0)} />
                    </div>
                </div>
            ))}
        </>
    );

    const paymentBody = () => (
        <form onSubmit={formik.handleSubmit}>
            {formik.values.payments.length === 0 && (
                <div className="mb-2">
                    <Message className='sideBarMessage' severity="error" text="Додайте валюту і спосіб оплати" />
                </div>
            )}
            {formik.values.payments.map((currencyAmount, index) => (
                <div className="grid py-3" key={index}>
                    <div className="col-10 p-inputgroup py-0 flex-1">
                        <span className="currencySpan p-inputgroup-addon">
                            <Dropdown
                                className="currencyDropDown"
                                value={currencyAmount.currency}
                                onChange={e => handlePaymentsCurrencyChange(index, e.value)}
                                options={currencies.map(currency => ({ label: currency, value: currency }))}
                            />
                        </span>
                        <InputText
                            name={`currencyValuePair[${index}].amount`}
                            value={currencyAmount.sum}
                            onChange={e => handlePaymentsAmountChange(index, e.target.value)}
                            className={formik.errors.currencyValuePair && formik.touched.currencyValuePair ? 'p-invalid' : ''}
                        />
                    </div>
                    <div className="col-2">
                        <Button className="noStyleButton" icon="pi pi-trash" severity="danger" type='button' text onClick={() => deletePayment(index)} />
                    </div>
                    <div className="col-10 py-0">
                        <Dropdown
                            className={formik.errors.payments && formik.errors.payments[index] && formik.touched.payments && formik.touched.payments[index] ? 'paymentTypeDropDown p-invalid' : 'paymentTypeDropDown'}
                            value={currencyAmount.paymentMethod}
                            onChange={e => handlePaymentMethodChange(index, e.value)}
                            options={paymentMethods.map(method => ({ label: method, value: method }))}
                            placeholder="Спосіб оплати"
                        />
                        {formik.errors.payments && formik.errors.payments[index] && formik.touched.payments && formik.touched.payments[index] && (
                            <small className="errorSmall">{formik.errors.payments[index].paymentMethod}</small>
                        )}
                    </div>
                </div>
            ))}
            <div className='mb-2'>
                <Message className='sideBarMessage' severity={unDividedSum > 0 ? "warn" : "success"} text={`Нерозподілена сума: ${unDividedSum} грн.`} />
            </div>
            <div>
                <Button label="+ Додати валюту" severity="info" type="button" className="mainPageButton editButton" onClick={addPaymentToFormik} />
                {formik.values.payments.length > 0 && <Button label="Оплатити" severity="success" type="submit" className="mainPageButton addButton mt-1" />}
            </div>
        </form>
    );

    const deleteBody = () => (
        <div className='grid'>
            <span className='mt-3 mb-2 text-800 text-large font-medium'> Підтвердіть видалення акту: #{formik.values.saleId}</span>
            <Button className="pageButton deleteButton text-large font-medium" severity='danger' type="button" onClick={deleteSaleHandler}>Видалити</Button>
        </div>
    )

    //#endregion

    const clientButtonName = `Обрати клієнта${formik.values.client?.name ? ` (${formik.values.client.name})` : ""}`;

    const calculateTotals = () => {
        const totals = {};

        target.forEach(item => {
            const quantity = item.quantity ?? 1;
            const saleAmount = item.price * quantity;
            const currency = item.currency;

            if (!totals[currency]) {
                totals[currency] = {
                    currency: currency,
                    sum: 0,
                    paymentMethod: null // Or any default value if needed
                };
            }

            totals[currency].sum += saleAmount;
        });

        return Object.values(totals);
    };

    const handlePaymentsPage = () => {
        formik.setFieldValue("products", target);
        const totals = calculateTotals();
        formik.setFieldValue('basePayments', totals);
        formik.setFieldValue('payments', [...totals]);
        
        let amountUAH = 0;
        let discounts = [];
        totals.forEach(total => {
            amountUAH += total.sum * currencyRates[total.currency];

            const existingDiscount = formik.values.discount.find(x => x.currency === total.currency);
            console.log(existingDiscount);

            discounts.push({
                currency: total.currency,
                amount: existingDiscount?.amount ?? 0
            });
        });

        setTotalSumUAH(amountUAH);
        formik.setFieldValue('discount', discounts);
        setPaymentsPage(true);
        setCartSidebarVisible(false);
    }

    const AddItemToCart = (element) => {
        const newCart = [...target];
        const updatedElement = {
            ...element,  
            quantity: 1  
        };
        newCart.push(updatedElement);
        setTarget(newCart);
    }

    const DeleteFromCart = (type, id) => {
        const newCart = [...target];
        const index = newCart.findIndex(x => x.item_type === type && x.id === id);
        newCart.splice(index, 1);
        setTarget(newCart);
    }

const startItems = (
  <div className="flex flex-wrap gap-2 ">
    {/* Buttons in one row */}
    <div className="flex w-full gap-2 sm:w-auto">
      <Button
        icon="pi pi-barcode"
        severity="secondary"
        outlined
        onClick={() => setQRCodeScannerVisible(true)}
      />
      <Button
        icon="pi pi-filter-slash"
        severity="secondary"
        outlined
        onClick={() => {
          setItemsSequence([]);
          setItemsCatalogue(null);
          setItemsCataloguePage(1);
        }}
      />
    </div>

    {/* Inputs in the next row */}
        <div className="flex w-full gap-2 sm:w-auto">
            <IconField iconPosition="left" className="w-8rem sm:w-auto">
                <InputIcon className="pi pi-search" />
                <InputText
                    placeholder="Товар"
                    onChange={(e) => { GetItems(undefined, 1, "products", undefined, e.target.value) }}
                />
            </IconField>
            <IconField iconPosition="left" className="w-8rem sm:w-auto">
                <InputIcon className="pi pi-search" />
                <InputText placeholder="Послуга"
                    onChange={(e) => { GetItems(undefined, 1, "services", undefined, e.target.value) }}
                />
            </IconField>
        </div>
    </div>
);

    const endItems = (
        <Button className="block lg:hidden flex align-items-center" 
        icon="pi pi-shopping-cart" 
        severity="secondary" 
        outlined 
        onClick={() => setCartSidebarVisible(true)} >
            {target.length > 0 && <Badge value={target.length} severity="info"></Badge>}
        </Button>
    );

    const actionBody = (rowData) => {
        return (
            <Button icon="pi pi-trash" severity="danger" text type='button' onClick={() => DeleteFromCart(rowData.item_type, rowData.id)} />
        )
    };

    const saleItem = (item) => {
        return (
            <div className="flex flex-wrap p-2 align-items-center gap-3">
                <div className="flex-1 flex flex-column gap-2">
                    <span className="font-bold"> {item.name}</span>
                    <div className="flex align-items-center gap-2">
                        <span>{`Ціна: ${formatCurrency(item.price, item.currency)}`}</span>
                    </div>
                </div>
            </div>
        );
    }

    const quantityEditor = (options) => {
        return (
            <InputNumber
                inputStyle={{ width: '100%' }}
                value={options.value}
                onValueChange={(e) => {
                    const updatedData = [...target];
                    updatedData[options.rowIndex].quantity = e.value;
                    setTarget(updatedData);
                }}
                min={1}
            />
        );
    };

    const cart = (<>
        <DataTable  value={target} header={"Замовлення"} emptyMessage="Додайте товари в замовлення" editMode="cell">
            <Column headerStyle={{ display: 'none' }} field="name" body={saleItem} style={{ width: '50%' }}/>
            <Column headerStyle={{ display: 'none' }} editor={(options) => quantityEditor(options)}field='quantity' style={{ width: '35%' }}/>
            <Column headerStyle={{ display: 'none' }} body={actionBody} style={{ width: '15%' }}/>
        </DataTable>
        {(target.length > 0 && !paymentsPage) && <Button className="pageButton" label='Оплатити' onClick={() => handlePaymentsPage()} />}
    </>)

    console.log(formik.values);

    return (
        <>
            <Toast ref={toast} />
            <MegaMenu orientation="horizontal" start={startItems} end={endItems} />
            {!paymentsPage && <div className='flex grid nested-grid'>
                <div className='col-12 lg:col-9'>
                    {itemsCatalogue === null && <div className='grid'>
                        <div className='col-12'>
                            <Card
                               style={{
                                width: "100%",
                                paddingTop: "34vh",
                                position: "relative"
                               }}
                                onClick={() => GetItems(undefined, 1, "products")}
                            >
                                <div className="itemContentStyle">
                                    Товари
                                </div>
                            </Card>
                        </div>
                        <div className='col-12'>
                            <Card
                               style={{
                                width: "100%",
                                paddingTop: "34vh",
                                position: "relative"
                               }}
                                onClick={() => GetItems(undefined, 1, "services")}
                            >
                                <div className="itemContentStyle">
                                    Послуги
                                </div>
                            </Card>
                        </div>
                    </div>}
                    {itemsCatalogue !== null && <div className='grid'>
                        {!isFilterPresent(filter) && <div className='col-6 md:col-4 lg:col-3 xl:col-2'>
                            <Card                                 
                                className="itemCardStyle"
                                onClick={() => ReturnToPreviousPage()}
                            >
                                <div className="itemContentStyle">
                                    <div className='flex flex-column align-items-center justify-content-center'>
                                        <i className="pi pi-angle-double-left" style={{ fontSize: '2rem' }}></i> До попередньої категорії
                                    </div>
                                </div>
                            </Card>
                        </div>}
                        {itemsCataloguePage > 1 && <div className='col-6 md:col-4 lg:col-3 xl:col-2'>
                            <Card
                                className="itemCardStyle"
                                onClick={() => GetItems(filter !== null ? undefined : itemsSequence[itemsSequence.length - 1], itemsCataloguePage - 1, saleType, undefined, filter)}
                            >
                                <div className="itemContentStyle">
                                    <i className="pi pi-angle-left" style={{ fontSize: '2rem' }}></i> Попередня сторінка
                                </div>
                            </Card>
                        </div>}
                        {itemsCatalogue.map(element => (<div className='col-6 md:col-4 lg:col-3 xl:col-2'>
                            <Card key={element.id}
                                className="itemCardStyle"
                                onClick={() => (element.item_type === "PRODUCT_GROUP" || element.item_type === "SERVICE_GROUP") ?
                                    GetItems(element.id, 1) :
                                    AddItemToCart(element)}
                            >
                                <div key={`pr_${element.id}`}className="itemContentStyle">
                                    <div className='flex flex-column align-text-center justify-content-center'>
                                        {element.item_type === "PRODUCT_GROUP" || element.item_type === "SERVICE_GROUP"
                                            ?
                                            element.name
                                            : (<>
                                                <div>{element?.name}</div>
                                                {element.item_type !== "SERVICE" && <div>Кількість: {element?.quantity}</div>}
                                                <div>Ціна: {formatCurrency(element?.price, element?.currency ?? "UAH")}</div>
                                            </>
                                            )}
                                    </div>
                                </div>
                            </Card>
                        </div>))}
                        {itemsCatalogue.length === 10 && <div className='col-6 md:col-4 lg:col-3 xl:col-2'>
                            <Card
                                className="itemCardStyle"
                                onClick={() => GetItems(filter !== null ? undefined : itemsSequence[itemsSequence.length - 1], itemsCataloguePage + 1, saleType, undefined, filter)}
                            >
                                <div className="itemContentStyle">
                                    <i className="pi pi-angle-right" style={{ fontSize: '2rem' }}></i> Наступна сторінка
                                </div>
                            </Card>
                        </div>}
                    </div>}
                </div>
                <div className='col-3 hidden lg:block'>
                    {cart}
                </div>
            </div>}
            {paymentsPage && <div className='grid mt-2'>
                    <div className="col-8 col-offset-2">
                        <div className="surface-section">
                            <div className="text-center">
                                <div className="font-medium text-xl text-900 mb-3">До оплати</div>
                            </div>
                            <ul className="list-none p-0 m-0">
                                {formik.values.basePayments.map((payment, index) => (
                                    <li key={index} className="flex flex-wrap justify-content-between align-items-center py-2 px-2 border-top-1 surface-border">
                                        <div className="text-500 w-6 md:w-2 font-medium">{payment.currency}</div>
                                        <div className="w-6 md:w-2 font-medium flex item-center justify-content-end ">
                                            <span className='mr-3 mt-2 text-800 text-large'>{formatCurrency(payment.sum, payment.currency)}</span>
                                        </div>
                                    </li>
                                ))}
                                <li key="totalUAH" className="flex flex-wrap justify-content-between align-items-center py-2 px-2 border-top-1 surface-border">
                                    <div className="text-500 font-medium">Еквівалентна сума в гривні</div>
                                    <div className="w-6 md:w-2 font-medium flex item-center justify-content-end ">
                                        <span className='mr-3 mt-2 text-800 text-large'>{formatCurrency(totalSumUAH, "UAH")}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        {existsDiscount() && (
                            <div className="surface-section">
                                <div className="text-center">
                                    <div className="font-medium text-xl text-900">Знижка</div>
                                </div>
                                <ul className="list-none p-0 m-0">
                                    {formik.values.discount.map((discount, index) => (discount.amount > 0 &&
                                        <li key={`discount${index}`} className="flex flex-wrap justify-content-between align-items-center py-2 px-2 border-top-1 surface-border">
                                            <div className="text-500 w-6 md:w-2 font-medium">{discount.currency}</div>
                                            <div className="w-6 md:w-2 font-medium flex text-500 justify-content-end">
                                                {formatCurrency(discount.amount, discount.currency)}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        <div className='grid'>
                            <div className='col-12 '>
                                <Button className="pageButton editButton justify-content-center font-bold" severity="info" onClick={() => sidebarHandler("discount")}>Додати знижку</Button>
                            </div>
                        </div>
                        <div className='grid'>
                            <div className='col-12 '>
                                <Button className="pageButton editButton justify-content-center font-bold" severity="info" onClick={() => sidebarHandler("client")}>{clientButtonName}</Button>
                            </div>
                        </div>
                        <div className='grid'>
                            {formik.values.isEdit && <div className='col-6 sm:col-6'>
                                <Button className="pr-1 pl-1 pageButton editButton responsiveButton justify-content-center font-bold" severity="info" onClick={() => sidebarHandler("delete")}>Видалити</Button>
                            </div>}
                            <div className={formik.values.isEdit ? "col-6 sm:col-6" : "col-12 sm:col-12"}>
                                <Button className="pr-1 pl-1 pageButton editButton responsiveButton justify-content-center font-bold" severity="info" onClick={() => showPaymentSideBar()}>Оплатити</Button>
                            </div>
                        </div>
                        <div className='grid'>
                            <div className='col-6'>
                                <Button className="pr-1 pl-1 pageButton editButton justify-content-center font-bold" severity="info" label="Назад" icon="pi pi-arrow-left" onClick={() => setPaymentsPage(false)} />
                            </div>
                            <div className='col-6'>
                                <Button className="pr-1 pl-1 pageButton editButton justify-content-center font-bold" severity="info" onClick={() => saveDraft()}>Зберегти і вийти</Button>
                            </div>
                        </div>
                    </div>
                </div>
            }                   
            <Sidebar className="responsive-sidebar" header={sideBarHeader} visible={visible} position="right" onHide={() => HideSideBar(sideBarType)}>
                {sideBarType === "client" && clientSideBarBody}
                {sideBarType === "discount" && discountSideBarBody()}
                {sideBarType === "payment" && paymentBody()}
                {sideBarType === "delete" && deleteBody()}
            </Sidebar>
            <Sidebar className="responsive-sidebar" visible={clientSideBarVisible} position="right" onHide={() => setClientSideBarVisible(false)}>
            <form onSubmit={newUserFormik.handleSubmit}>
                <div className="radioButtonBlock">
                    <div className="accountingRadio">
                        <RadioButton value="INDIVIDUAL" checked={newUserFormik.values.type === "INDIVIDUAL"} onChange={(e) => { newUserFormik.setFieldValue('type', "INDIVIDUAL"); }} />
                        <label className="ml-2">Фізична особа</label>
                    </div>
                    <div className="accountingRadio">
                        <RadioButton value="LEGAL" checked={newUserFormik.values.type === "LEGAL"} onChange={(e) => { newUserFormik.setFieldValue('type', "LEGAL"); }} />
                        <label className="ml-2">Юридична особа</label>
                    </div>
                </div>
                {newUserFormik.values.type === "INDIVIDUAL" && (<>
                    <div className="formInput">
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="last_name"
                                    value={newUserFormik.values.individual_cagent_data?.last_name}
                                    onChange={(e) => { newUserFormik.setFieldValue('individual_cagent_data.last_name', e.target.value) }}
                                    className={newUserFormik.errors.indLastName && newUserFormik.touched.individual_cagent_data?.last_name ? 'p-invalid' : ''}
                                />
                                <label>Прізвище контрагента</label>
                            </FloatLabel>
                            {newUserFormik.errors.indLastName && newUserFormik.touched.individual_cagent_data?.last_name && (<small className="errorSmall">{newUserFormik.errors.indLastName}</small>)}
                        </div>
                    </div>
                    <div className="formInput">
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="first_name"
                                    value={newUserFormik.values.individual_cagent_data?.first_name}
                                    onChange={(e) => { newUserFormik.setFieldValue('individual_cagent_data.first_name', e.target.value) }}
                                    className={newUserFormik.errors.indFirstName && newUserFormik.touched.individual_cagent_data?.first_name ? 'p-invalid' : ''}
                                />
                                <label>Імя контрагента</label>
                            </FloatLabel>
                            {newUserFormik.errors.indFirstName && newUserFormik.touched.individual_cagent_data?.first_name && (<small className="errorSmall">{newUserFormik.errors.indFirstName}</small>)}
                        </div>
                    </div>
                    <div className="formInput">
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="surname"
                                    value={newUserFormik.values.individual_cagent_data?.surname}
                                    onChange={(e) => { newUserFormik.setFieldValue('individual_cagent_data.surname', e.target.value) }}
                                />
                                <label>По-батькові контрагента</label>
                            </FloatLabel>
                        </div>
                    </div>
                    <div className="formInput">
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <Calendar
                                    value={newUserFormik.values.individual_cagent_data.date_of_birth}
                                    onChange={(e) => { newUserFormik.setFieldValue('individual_cagent_data.date_of_birth', e.target.value) }}
                                    showIcon
                                    locale="ua"
                                />
                                <label>Дата народження</label>
                            </FloatLabel>
                        </div>
                    </div>
                </>)}
                {newUserFormik.values.type === "LEGAL" && (<>
                    <div className="formInput">
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="long_name"
                                    value={newUserFormik.values.legal_cagent_data.long_name}
                                    onChange={(e) => { newUserFormik.setFieldValue('legal_cagent_data.long_name', e.target.value) }}
                                    className={newUserFormik.errors.legalLongName && newUserFormik.touched.legal_cagent_data?.long_name ? 'p-invalid' : ''}
                                />
                                <label>Повна назва контрагента</label>
                            </FloatLabel>
                            {newUserFormik.errors.legalLongName && newUserFormik.touched.legal_cagent_data?.long_name && (<small className="errorSmall">{newUserFormik.errors.legalLongName}</small>)}
                        </div>
                    </div>
                    <div className="formInput">
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="short_name"
                                    value={newUserFormik.values.legal_cagent_data.short_name}
                                    onChange={(e) => { newUserFormik.setFieldValue('legal_cagent_data.short_name', e.target.value) }}
                                    className={newUserFormik.errors.legalShortName && newUserFormik.touched.legal_cagent_data?.short_name ? 'p-invalid' : ''}
                                />
                                <label>Скорочена назва контрагента</label>
                            </FloatLabel>
                            {newUserFormik.errors.legalShortName && newUserFormik.touched.legal_cagent_data?.short_name && (<small className="errorSmall">{newUserFormik.errors.legalShortName}</small>)}
                        </div>
                    </div>
                    <div className="formInput">
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="representative"
                                    value={newUserFormik.values.legal_cagent_data.representative}
                                    onChange={(e) => { newUserFormik.setFieldValue('legal_cagent_data.representative', e.target.value) }}
                                />
                                <label>Представник</label>
                            </FloatLabel>
                        </div>
                    </div>
                    <div className="formInput">
                        <div className="p-inputgroup flex-column">
                            <FloatLabel>
                                <InputText
                                    name="edrpou"
                                    value={newUserFormik.values.legal_cagent_data.edrpou}
                                    onChange={(e) => { newUserFormik.setFieldValue('legal_cagent_data.edrpou', e.target.value) }}
                                    className={newUserFormik.errors.legalEdrpou && newUserFormik.touched.legal_cagent_data?.edrpou ? 'p-invalid' : ''}
                                />
                                <label>ЄДРПОУ</label>
                            </FloatLabel>
                            {newUserFormik.errors.legalEdrpou && newUserFormik.touched.legal_cagent_data?.edrpou && (<small className="errorSmall">{newUserFormik.errors.legalEdrpou}</small>)}
                        </div>
                    </div>
                </>)}
                <Button className="pr-1 pl-1 pageButton editButton justify-content-center font-bold" severity="info" type="submit">Створити клієнта</Button>
                </form>
            </Sidebar>
            <Sidebar className="p-0" visible={QRCodeScannerVisible} onHide={() => setQRCodeScannerVisible(false)} fullScreen>
                <BarcodeScannerComponent
                    className="m-0 p-0"
                    videoConstraints={{
                        zoom: 2, 
                        facingMode: "environment",
                    }}
                    onUpdate={(err, result) => {
                        if (result) handleScannedObject(result.text);
                        else console.log(err);
                    }}
                    onError={(err) => {
                        console.log(err);
                        toast.current.show({severity: "error",  detail: `${err.message || err}`, life: 2000 })
                    }}
                />
            </Sidebar>
            <Sidebar className="mainPageSideBar" visible={cartSidebarVisible} position="right" onHide={() => setCartSidebarVisible(false)}>
                {cart}
            </Sidebar>
        </>
    )
};

export default NewSale;